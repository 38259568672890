/**
 * @generated SignedSource<<322be9c66850bd3e3671b3c4cde90aa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NodeInput = {
  id: any;
};
export type postCreateMutation$variables = {
  category: NodeInput;
  content: string;
  paidContent?: string | null;
  tagBodies?: ReadonlyArray<string> | null;
  title: string;
};
export type postCreateMutation$data = {
  readonly postCreate: {
    readonly content?: string;
    readonly createdAt?: any;
    readonly id?: any;
    readonly title?: string;
  };
};
export type postCreateMutation = {
  response: postCreateMutation$data;
  variables: postCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "category"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paidContent"
},
v3 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "tagBodies"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category"
      },
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "paidContent",
        "variableName": "paidContent"
      },
      {
        "kind": "Variable",
        "name": "tagBodies",
        "variableName": "tagBodies"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "kind": "ObjectValue",
    "name": "newPostInput"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Post",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "postCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "postCreate",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "postCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "postCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de4d9400a140901085cd09da33626f3c",
    "id": null,
    "metadata": {},
    "name": "postCreateMutation",
    "operationKind": "mutation",
    "text": "mutation postCreateMutation(\n  $title: String!\n  $content: String!\n  $paidContent: String = null\n  $tagBodies: [String!] = []\n  $category: NodeInput!\n) {\n  postCreate(newPostInput: {title: $title, content: $content, paidContent: $paidContent, tagBodies: $tagBodies, category: $category}) {\n    __typename\n    ... on Post {\n      id\n      title\n      content\n      createdAt\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "696dd45bffd0590dfb71532a360f08ba";

export default node;
