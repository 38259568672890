/**
 * @generated SignedSource<<27923b74a161a5b6661cd9ef06fc1848>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PostReaderPersonaStatisticsInput = {
  minRevisit: number;
  postId: any;
  resultLimit?: number | null;
};
export type postReaderStatisticsGetQuery$variables = {
  opt: PostReaderPersonaStatisticsInput;
};
export type postReaderStatisticsGetQuery$data = {
  readonly getPostReaderStatistics: {
    readonly categoryScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
    readonly genderScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
    readonly jobScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
    readonly tagScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
  };
};
export type postReaderStatisticsGetQuery = {
  response: postReaderStatisticsGetQuery$data;
  variables: postReaderStatisticsGetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "opt"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "score",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "opt",
        "variableName": "opt"
      }
    ],
    "concreteType": "PersonaStatistics",
    "kind": "LinkedField",
    "name": "getPostReaderStatistics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "categoryScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "genderScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "jobScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "tagScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "postReaderStatisticsGetQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "postReaderStatisticsGetQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d15e9f7de1e37eb0431cad190ad5af9a",
    "id": null,
    "metadata": {},
    "name": "postReaderStatisticsGetQuery",
    "operationKind": "query",
    "text": "query postReaderStatisticsGetQuery(\n  $opt: PostReaderPersonaStatisticsInput!\n) {\n  getPostReaderStatistics(opt: $opt) {\n    categoryScores {\n      label\n      score\n    }\n    genderScores {\n      label\n      score\n    }\n    jobScores {\n      label\n      score\n    }\n    tagScores {\n      label\n      score\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4362ec7c90a9e191a6ff5dc82a718416";

export default node;
