/**
 * @generated SignedSource<<5444eeb69a3f3b689c8712a1068ae3e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GetPersonaFollowerStatisticsInput = {
  personaId: any;
  resultLimit?: number | null;
};
export type follwerStatsAllGetQuery$variables = {
  opt: GetPersonaFollowerStatisticsInput;
};
export type follwerStatsAllGetQuery$data = {
  readonly getPersonaFollowersStatistics: {
    readonly categoryScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
    readonly genderScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
    readonly jobScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
    readonly tagScores: ReadonlyArray<{
      readonly label: string;
      readonly score: number;
    }>;
  };
};
export type follwerStatsAllGetQuery = {
  response: follwerStatsAllGetQuery$data;
  variables: follwerStatsAllGetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "opt"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "score",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "opt",
        "variableName": "opt"
      }
    ],
    "concreteType": "PersonaStatistics",
    "kind": "LinkedField",
    "name": "getPersonaFollowersStatistics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "categoryScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "genderScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "jobScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FieldScore",
        "kind": "LinkedField",
        "name": "tagScores",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "follwerStatsAllGetQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "follwerStatsAllGetQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2c868bea5570fe57a174e6fb8c26520a",
    "id": null,
    "metadata": {},
    "name": "follwerStatsAllGetQuery",
    "operationKind": "query",
    "text": "query follwerStatsAllGetQuery(\n  $opt: GetPersonaFollowerStatisticsInput!\n) {\n  getPersonaFollowersStatistics(opt: $opt) {\n    categoryScores {\n      label\n      score\n    }\n    genderScores {\n      label\n      score\n    }\n    jobScores {\n      label\n      score\n    }\n    tagScores {\n      label\n      score\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01ee3efbf5361c7e049777fbf2440652";

export default node;
