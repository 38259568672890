/**
 * @generated SignedSource<<723c8e90d6d8467b631f1067c1c23f47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Gender = "FEMALE" | "MALE" | "%future added value";
export type Job = "ART" | "EDUCATOR" | "EMPLOYEE" | "ETC" | "FINANCE" | "IT" | "JOB_SEEKER" | "STUDENT" | "%future added value";
export type CategoryIDInput = {
  id: any;
};
export type personaCreateMutation$variables = {
  birthYear?: number | null;
  gender?: Gender | null;
  introduction?: string | null;
  isPublic?: boolean | null;
  job?: Job | null;
  nickname: string;
  preferredCategories?: ReadonlyArray<CategoryIDInput> | null;
  preferredTagBodies?: ReadonlyArray<string> | null;
};
export type personaCreateMutation$data = {
  readonly personaCreate: {
    readonly id?: any;
  };
};
export type personaCreateMutation = {
  response: personaCreateMutation$data;
  variables: personaCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "birthYear"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gender"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "introduction"
},
v3 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "isPublic"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "job"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nickname"
},
v6 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "preferredCategories"
},
v7 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "preferredTagBodies"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "birthYear",
        "variableName": "birthYear"
      },
      {
        "kind": "Variable",
        "name": "gender",
        "variableName": "gender"
      },
      {
        "kind": "Variable",
        "name": "introduction",
        "variableName": "introduction"
      },
      {
        "kind": "Variable",
        "name": "isPublic",
        "variableName": "isPublic"
      },
      {
        "kind": "Variable",
        "name": "job",
        "variableName": "job"
      },
      {
        "kind": "Variable",
        "name": "nickname",
        "variableName": "nickname"
      },
      {
        "kind": "Variable",
        "name": "preferredCategories",
        "variableName": "preferredCategories"
      },
      {
        "kind": "Variable",
        "name": "preferredTagBodies",
        "variableName": "preferredTagBodies"
      }
    ],
    "kind": "ObjectValue",
    "name": "newPersonaInput"
  }
],
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Persona",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "personaCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "personaCreate",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "personaCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "personaCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v9/*: any*/),
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "efddf8631a0a1b5d64e13f8d0ce087b4",
    "id": null,
    "metadata": {},
    "name": "personaCreateMutation",
    "operationKind": "mutation",
    "text": "mutation personaCreateMutation(\n  $nickname: String!\n  $birthYear: Int\n  $gender: Gender\n  $introduction: String\n  $isPublic: Boolean = true\n  $job: Job\n  $preferredTagBodies: [String!] = []\n  $preferredCategories: [CategoryIDInput!] = []\n) {\n  personaCreate(newPersonaInput: {nickname: $nickname, birthYear: $birthYear, gender: $gender, introduction: $introduction, isPublic: $isPublic, job: $job, preferredTagBodies: $preferredTagBodies, preferredCategories: $preferredCategories}) {\n    __typename\n    ... on Persona {\n      id\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe8e6c1327c6ad6fd1d175b232d1b5e6";

export default node;
