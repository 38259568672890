/**
 * @generated SignedSource<<91693132e317383ce7500a990753d2e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Tier = "TIER_1" | "TIER_2" | "TIER_3" | "TIER_4" | "TIER_5" | "TIER_6" | "%future added value";
export type postGetQuery$variables = {
  postId: any;
};
export type postGetQuery$data = {
  readonly getPost: {
    readonly author: {
      readonly id: any;
      readonly nickname: string;
    };
    readonly bookmarkCnt: number;
    readonly category: {
      readonly body: string;
    } | null;
    readonly commentCnt: number;
    readonly comments: ReadonlyArray<{
      readonly body: string;
      readonly createdAt: any;
      readonly id: any;
      readonly persona: {
        readonly id: any;
        readonly nickname: string;
      };
    }>;
    readonly content: string;
    readonly contentPreview: string | null;
    readonly createdAt: any;
    readonly id: any;
    readonly likeCnt: number;
    readonly paidContent: string | null;
    readonly requiredMembershipTier: Tier | null;
    readonly tags: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly body: string;
        };
      }>;
    };
    readonly title: string;
  };
};
export type postGetQuery = {
  response: postGetQuery$data;
  variables: postGetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "postId",
        "variableName": "postId"
      }
    ],
    "concreteType": "Post",
    "kind": "LinkedField",
    "name": "getPost",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contentPreview",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "likeCnt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paidContent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bookmarkCnt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requiredMembershipTier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TagConnection",
        "kind": "LinkedField",
        "name": "tags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "category",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Persona",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commentCnt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "comments",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Persona",
            "kind": "LinkedField",
            "name": "persona",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "postGetQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "postGetQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "c6cc43a7063c127c11b05f94ee190b7b",
    "id": null,
    "metadata": {},
    "name": "postGetQuery",
    "operationKind": "query",
    "text": "query postGetQuery(\n  $postId: GlobalID!\n) {\n  getPost(postId: $postId) {\n    id\n    content\n    contentPreview\n    createdAt\n    likeCnt\n    paidContent\n    bookmarkCnt\n    requiredMembershipTier\n    title\n    tags {\n      edges {\n        node {\n          body\n        }\n      }\n    }\n    category {\n      body\n    }\n    author {\n      nickname\n      id\n    }\n    commentCnt\n    comments {\n      id\n      body\n      createdAt\n      persona {\n        id\n        nickname\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "30a3e65584ba17af429a5163c3db1ef0";

export default node;
