/**
 * @generated SignedSource<<ac0b90b03d5f577037ca88eb3588b81f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type postDeleteMutation$variables = {
  postId: any;
};
export type postDeleteMutation$data = {
  readonly deletePost: any | null;
};
export type postDeleteMutation = {
  response: postDeleteMutation$data;
  variables: postDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "postId",
        "variableName": "postId"
      }
    ],
    "kind": "ScalarField",
    "name": "deletePost",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "postDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "postDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "544902049a0d82a1d4b38c46a7967275",
    "id": null,
    "metadata": {},
    "name": "postDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation postDeleteMutation(\n  $postId: GlobalID!\n) {\n  deletePost(postId: $postId)\n}\n"
  }
};
})();

(node as any).hash = "8ce49c8d7d5b5d1f2c9d8ccbe25ec1e7";

export default node;
