const tagSeries: any[] = [{
  data: [{
    x: '교육자',
    y: 30,
  },{
    x: '학생',
    y: 12,
  },{
    x: '예술업계',
    y: 7,
  },
  ],
}];

export default tagSeries;