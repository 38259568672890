/**
 * @generated SignedSource<<09f35ce5e3ec1284ca2bbd7f12e3cde8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type logoutPostMutation$variables = {};
export type logoutPostMutation$data = {
  readonly logout: any | null;
};
export type logoutPostMutation = {
  response: logoutPostMutation$data;
  variables: logoutPostMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logout",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "logoutPostMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "logoutPostMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "aeee72eebdedfcf6bd92f549308b8f4d",
    "id": null,
    "metadata": {},
    "name": "logoutPostMutation",
    "operationKind": "mutation",
    "text": "mutation logoutPostMutation {\n  logout\n}\n"
  }
};
})();

(node as any).hash = "c4363d495c88fb8a460725823a7f50fa";

export default node;
