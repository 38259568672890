const categorySeries: any[] = [{
  data: [{
    x: '사회',
    y: 10,
  }, {
    x: '문화',
    y: 18,
  }, {
    x: 'IT',
    y: 13,
  },{
    x: '연예',
    y: 12,
  },
  ],
}];

export default categorySeries;