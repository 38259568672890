const tagSeries: any[] = [{
  data: [{
    x: '인증',
    y: 7,
  }, {
    x: '꿀팁',
    y: 30,
  }, {
    x: '공부',
    y: 12,
  },{
    x: '연애',
    y: 2,
    },
  {
    x: '사진',
    y: 2,
    },
  {
    x: '취미',
    y: 2,
  },
  ],
}];

export default tagSeries;