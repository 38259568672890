/**
 * @generated SignedSource<<12728a248eb98fcd98d6669b427867be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pagination_postListGetQuery$variables = {
  after?: string | null;
  first?: number | null;
  id: any;
};
export type pagination_postListGetQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"postPaginationFragment">;
};
export type pagination_postListGetQuery = {
  response: pagination_postListGetQuery$data;
  variables: pagination_postListGetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = [
  (v1/*: any*/),
  {
    "fields": [
      (v3/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "authorFilter"
  },
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "sortingOpt",
    "value": {
      "direction": "DESC",
      "sortBy": "ID"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pagination_postListGetQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "postPaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pagination_postListGetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PostConnection",
        "kind": "LinkedField",
        "name": "getPublicPosts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PostEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Post",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contentPreview",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "sortingOpt",
          "authorFilter"
        ],
        "handle": "connection",
        "key": "Edges_getPublicPosts",
        "kind": "LinkedHandle",
        "name": "getPublicPosts"
      }
    ]
  },
  "params": {
    "cacheID": "bb0ac166344517266ec8c7a85c7ce745",
    "id": null,
    "metadata": {},
    "name": "pagination_postListGetQuery",
    "operationKind": "query",
    "text": "query pagination_postListGetQuery(\n  $after: String\n  $first: Int = 10\n  $id: GlobalID!\n) {\n  ...postPaginationFragment_XKRaI\n}\n\nfragment postPaginationFragment_XKRaI on Query {\n  getPublicPosts(first: $first, after: $after, sortingOpt: {direction: DESC, sortBy: ID}, authorFilter: {id: $id}) {\n    edges {\n      node {\n        id\n        content\n        contentPreview\n        title\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "353036adcfd14aff32580001b4459f90";

export default node;
